import { createApp } from 'vue'
import AjaxLoader from '../vue-components/AjaxLoader.vue'

document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector('#flyer-form')) {
    const appearedAtDate = document.querySelector('#flyer_appeared_at_date').value
    const appearedAtHour = document.querySelector('#flyer_appeared_at_hour').value
    const disappearedAtDate = document.querySelector('#flyer_disappeared_at_date').value
    const disappearedAtHour = document.querySelector('#flyer_disappeared_at_hour').value

    createApp({
      components: {
        AjaxLoader,
      },
      data() {
        return {
          appearedAt: null,
          appearedAtDate: appearedAtDate,
          appearedAtHour: appearedAtHour,
          disappearedAt: null,
          disappearedAtDate: disappearedAtDate,
          disappearedAtHour: disappearedAtHour,
        }
      },
      methods: {
        setAppearedAt() {
          this.appearedAt = `${this.appearedAtDate} ${this.appearedAtHour}:00`
        },
        setDisappearedAt() {
          this.disappearedAt = `${this.disappearedAtDate} ${this.disappearedAtHour}:00`
        },
      },
      watch: {
        appearedAtDate(value) {
          this.setAppearedAt()
        },
        appearedAtHour(value) {
          this.setAppearedAt()
        },
        disappearedAtDate(value) {
          this.setDisappearedAt()
        },
        disappearedAtHour(value) {
          this.setDisappearedAt()
        },
      },
      mounted() {
        console.log('flyer-form.mounted', this)
        this.setAppearedAt()
        this.setDisappearedAt()
      }
    }).mount('#flyer-form')
  }
})
