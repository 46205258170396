<template>
  <draggable v-model="genres" tag="tbody" item-key="id" handle=".draggable-handle" drag-class="bg-white">
    <template v-slot:item="{ element, index }">
      <tr>
        <td>
          <div class="draggable-handle cursor-ns-resize flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
            </svg>
          </div>
          <input type="hidden" v-bind:name="`${namePrefix}[${element.id}][order]`" v-bind:value='index + 1'>
        </td>
        <td>
          <a v-bind:href="element.edit_path" class='btn btn-primary btn-sm'>編集</a>
        </td>
        <td>{{ element.label }}</td>
        <td>
          <a v-bind:href="element.flyers_path">{{ element.flyers_count }}件</a>
        </td>
        <td>
          <button class='btn btn-danger btn-sm' type='button' v-on:click='remove(element.id)'>削除</button>
        </td>
      </tr>
    </template>
  </draggable>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    genres: {
      type: Array,
      default() {
        return []
      },
    },
    namePrefix: {
      type: String,
      required: true,
    }
  },
  methods: {
    async remove(id) {
      if(confirm('削除しますか？')) {
        const { data } = await axios.delete(`/genres/${id}`)
        switch(data.status) {
          case 'success':
            // TODO: とりあえずリロード
            location.reload()
            break
          case 'error':
            alert(data.message)
            break
        }
      }
    }
  },
  mounted() {
    console.log('GenresTable.mounted')
  }
}
</script>
