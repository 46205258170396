import { createApp } from 'vue'

document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector('#advertiser-form')) {
    const paymentType = document.querySelector('#advertiser_payment_type').value
    const priority = document.querySelector('#advertiser_priority').value

    createApp({
      data() {
        return {
          paymentType,
          priority,
        }
      },
      methods: {
        changePaymentType() {
          this.paymentType = this.$refs.paymentTypeSelect.value
          switch(this.paymentType) {
            case 'paid':
              this.priority = 10
              break;
            case 'deferred':
              this.priority = 50
              break;
          }
        },
      },
      mounted() {
        console.log('advertiser-form.mounted', this)
        if(!this.priority) this.changePaymentType()
      }
    }).mount('#advertiser-form')
  }
})
