import { createApp } from 'vue'

import GenresTableBody from '../vue-components/GenresTableBody.vue'

if(document.querySelector('#genres-list')) createApp({
  components: {
    GenresTableBody,
  },
  data() {
    return {
      genres: window.GENRES
    }
  },
  mounted() {
    console.log('genres-list.mounted', this)
  }
}).mount('#genres-list')
